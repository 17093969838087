.cardContainer:hover {
    box-shadow: 5px 29px 32px -13px rgba(21,19,19,0.51);
    -webkit-box-shadow: 5px 29px 32px -13px rgba(21,19,19,0.51);
    -moz-box-shadow: 5px 29px 32px -13px rgba(21,19,19,0.51);
}
.cardImg {
    height: 160px;
    margin: 0;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    min-width: 210px;

}
.cardBody{
    background-color: #fff;
    border: 2px solid#E4E4E4;
    border-top: none;
    padding-left: 20px;
    padding-right: 15px;
    padding-bottom: 7px;
    margin-top: 0;
    padding-top: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-top: -5px;
}
.cardTitle {
    font-size: 1rem;
    font-weight:600;
    
}.cardDetails{
    font-size: 10px;
    font-weight: 400;
    margin-top: 0.3rem;
    color: #707070;
}.cardFrom{
    font-size: 11px;
    font-weight: 500;
    margin-top: 0.8rem;
}
.cardPrice{
    font-size: 15px;
    font-weight: 500;

}