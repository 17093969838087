body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa;
  margin: 0 auto;
  font-family: 'Roboto', sans-serif; /* Establece la tipografía Roboto para los demás textos */
  font-family: 'Josefin Sans', sans-serif;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h1, h2, h3, h4, h5, h6, p {
margin: 0;
}
a {
  text-decoration: none;
  color: #f1f1f1;
}

