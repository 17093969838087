.footerContainer{
    background-color: #fff;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 0.8rem;
    max-width: 1200px;
    margin: 0 auto; 
    align-items: center;
}
.footerContainer p {
    font-size: 15px;
    max-width: 500px;
    text-align: center;
    color: #929292;
}
@media (max-width: 1000px) {
    .footerContainer p {
        font-size: 13px;
    }
}
.footerBody{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    margin: 10px auto;
    margin-bottom: 20px;
    justify-items: center;
   /* padding: 0 10vw;*/
}.footerContainer h3 {
font-size: 15px;
}
.footerBody p {
font-size: 15px;
text-align: start;
}

@media (max-width: 1000px) {
    .footerContainer{
        padding:0 10px
    }
    .footerContainer h3 {
        font-size: 12px;
        }
    .footerBody p {
        font-size: 9px;
    }
}
