.loteryh3 {
    font-family: 'Josefin Sans', sans-serif;
    font-weight: bold; /* Establece la tipografía Josefin para el encabezado h1 */
    font-size: 24px;
}

.bg-orange {
    background-color: #FFA500; /* Agrega el color naranja al fondo */
}

.rounded-lg {
    border-radius: 1rem; /* Aumenta el radio de los bordes */
}

.shadow {
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15); /* Agrega una sombra suave */
}

.border-orange {
    border-color: #FFA500;
}

.btn:hover {
    background-color: #FF8C00; /* Cambiar a un tono naranja más oscuro */
    border-color: #FF8C00;
}

.btn {
    border-radius: 10px 10px 10px 10px !important; 
}

.form-control:focus{
    border-color: #FFA500;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.035), 0 0 4px rgba(255, 0, 0, 0.6);
}    

.form-control {
    border-radius: 10px 10px 10px 10px !important;
    
}
.form-control::placeholder {
    color: #d3d3d3;
    opacity: 1; /* Firefox */
}

.card {
    border-radius: 10px 10px 10px 10px !important;
}

.card-header {
    border-radius: 10px 10px 0px 0px !important;
} 

span {
    color: #FF6961;
}
#btn_submit{
    width: 100%;
}
#btn_submit:focus{
    background-color: #FFA500;
    border-color: #FF8C00;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.035), 0 0 4px rgba(255, 0, 0, 0.6);
}