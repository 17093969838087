/* .AppBanner{
  background-repeat: no-repeat;
  background-size: auto;
  width: 100%;
  position: absolute;
  z-index: -1;
} */
.container{
  width: 90%;
  padding: 0 5px;
  margin: 0 auto;
  max-width: 1200px;
}
@media (max-width: 1000px) {
  .container{
    width: 100%;
    padding: 0;
  }
}

.bannerText{
  padding-left: 5rem;
  padding-top: 3rem;
  background-image: url('/public/FerrieBanner.png');
  background-size: cover  ;
  background-repeat: no-repeat;
  height: 28rem;
  background-position-y: 'center';
  padding: 0 auto;
}
.bannerText h1{
  font-size: 4.5rem;
  font-weight: 500;
  color: #fff;
  max-width: 1200px;
  margin: 0 auto;

}
.bannerhome{
  max-width: 1200px;
  margin: 0 auto;
  
}
@media (max-width: 1000px) {
  .bannerText{
    padding-left: 1rem;
    padding-top: 1rem;
    background-position-x:50%
  }
  .bannerText h1{
    font-size: 2.0rem;
    max-width: 90vw;
    padding: 0;
  }
}
.bannerText h2{
  color: #fff;
  margin-bottom: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}
.promotionCard .promotionCardLarge::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.promotionCard  .promotionCardLarge{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.promotionCard{
 display: flex;
 gap: 1rem;
}
@media (min-width: 200px) {
  .promotionCard{
    overflow: scroll;
  }
}
.promotionCardLarge{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
}
@media (min-width: 200px) {
  .promotionCardLarge{
    overflow: scroll;
  }
}

.AppOferts{
  display: flex;
  gap: 1rem;  
  overflow: scroll;
  margin-bottom: 10px;
  padding-bottom:10px ;
 /*width: 90vw;*/
 max-width: 1200px;
}
.AppSuscribe{
  margin: 0 auto;
  
}
.imageParalax{
  height: 16rem;
  background-color: #929292;
}
/* Styles Helpers*/
.hover:hover{
  cursor: pointer;
}
.subtitle{
  font-size: 1.3rem;
  font-weight: 500;
  color: #000;
}
.subtitle2{
  font-size: 1.1rem;
  font-weight: 500;
  color: gray;
}
.bodyText{
  font-size: 10px;
  color: #929292;

}
.bodyTextSecundary{
  font-size: 10px;
  font-weight: 400;
  color:#FDB54E;
}
.separator{
margin: 0.3rem;
}
.primaryButton{
  color: #000;
  padding: 0.3rem 1rem;
  font-size: 0.8rem;
  background-color: #FDB54E;
  border-radius: 0.8rem;
  font-weight: 400;
  border-width: 0;
  cursor: pointer;
}
.secundaryButton{
  color: #f1f1f171;
  font-size: 0.7rem;
}
.tertiaryButton{
  color: #fff;
  padding: 0.4rem 0.5rem;
  padding-right: 60px;
  font-size: 1rem;
  background-color: #000;
  border-radius: 0.8rem;
  font-weight: 600;
}
.flexCenter{
  display: flex;
  justify-content: center;
  align-items: center;
}
.flexJustify{
  display: flex;
  justify-content: space-between;

}
.col{
  display: flex;
  flex-direction: row;
  gap:5px;
  align-items: center;
}
@media (max-width: 1000px) {
  .col{
flex-direction: column;
}}
.gap{
  display: flex;
  gap: 0.5rem;
}
