.headerContainer{
    background-color: black;
    padding: 0 2rem;  
}
/* Top Header*/
@media (max-width: 550px) {
    .headerTopContainer{
      flex-direction: column-reverse;
      gap: 0.8rem;
    }
  }
.headerTopContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0;
    max-width: 1200px;
    margin: 0 auto; 
    border-bottom: 1px solid #f1f1f183;
}
.headerContactIcons{
    display: flex;
    gap: 0.8rem;
    color: #FDB54E;

}
.headerContactIcons a{
    color: #FDB54E;
}
.headerContactIcons a:hover{
    color: #FFF;
    
}
.headerButtons{
    display: flex;
    gap: 0.5rem;
    align-items: center;
}
.headerButtons a:hover{
    color: white;
}
/* Navigation*/
.headerNav {
    display: flex;
    justify-content: space-around;
    max-width: 1200px;
    margin: 0 auto; 
    align-items: center;
    padding: 2rem;
    gap: 0.3rem;
}
.headerNav a{
    color: #FDB54E;
    font-size: 0.95rem;
    font-weight: 500;
    padding: 0;
}
@media (max-width: 550px) {
    .headerNav a{
        font-size: 0.8rem;
    }
  }
.headerNav a:hover{
    color: #fff;
    text-decoration: none;
    
}