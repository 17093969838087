
.serviceIcon:hover{
    background-color: #ededed;
    text-decoration: none;
}
.iconTitle{
    color:#FDB54E;
    text-decoration: none;
}
.iconTitle:hover{
    text-decoration: none;
    color:#000;
}
