.containerContactForm{
    width: 320px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    background-color: #fff;
    top: -80px;
    box-shadow: 0px 31px 55px -13px rgba(95,93,93,0.65);
    -webkit-box-shadow: 0px 31px 55px -13px rgba(95,93,93,0.65);
    -moz-box-shadow: 0px 31px 55px -13px rgba(95,93,93,0.65);
}
.contactForm{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 80%;
    margin: 0 auto;
    
 
}
@media (max-width: 1000px) {
    .containerContactForm{
        top:0;
        margin: 20px auto 60px auto;
        width: 90%;
    }
    .contactForm{
        padding: 0;
        margin: 0 auto;
    }
}
