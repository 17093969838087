.promotionBannerContainer{
    background-color: #FDB54E;
    height: 12rem;
    width: 100%;
    overflow: hidden;
    margin-top: 50px;
    border-radius: 10px;
    display: flex;
    gap: 4rem;
    box-shadow: 5px 29px 32px -13px rgba(21,19,19,0.51);
    -webkit-box-shadow: 5px 29px 32px -13px rgba(21,19,19,0.51);
    -moz-box-shadow: 5px 29px 32px -13px rgba(21,19,19,0.51);
    margin-bottom: 60px;
    
}

@media (max-width: 1000px) {
    .promotionBannerContainer{
      width: 95%;
      margin: 0 auto;
      flex-direction: column;
      gap: 0;
       margin-bottom: 30px;
    }
  }
.promotionBannerImg{
    flex:0.8;
    height: 100%;
    width: 100%;
    background-image: url('../../../public/collage.png');
    background-size: cover;

}
@media (max-width: 1000px) {
    .promotionBannerImg{
      background-size: 80%;
      background-position: bottom;
    }
  }
.promotionBannerContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
@media (min-width: 500px) {
    .promotionBannerContent {
        flex: 0.9;
        
    }
  }
.promotionBannerContent h2 {
    width: 80%;
    font-size: 25px;
    font-weight: 600;
    color: white;
}
.promotionBannerContent p {
    width: 40%;
    color: white;
}
@media (max-width: 1000px) {
    .promotionBannerContent {
        padding-left: 10px;
    }
    .promotionBannerContent h2 {
        font-size: 18px;
        width: 100%;
        
    }
    .promotionBannerContent p {
        font-size: 15px;
        width: 100%;
    }
  }
